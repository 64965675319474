import React from 'react';
import { graphql } from 'gatsby';

import Container from 'components/organisms/Container';
import Accordeon from 'components/molecules/Accordeon';
import Banner from 'components/molecules/Banner';
import IntroSection from 'components/atoms/IntroSection';
import Seo from 'components/atoms/Seo';

const Zajecia = ({
  data: {
    contentfulActivities: {
      introText,
      banner: { bannerSlide },
      activitiesList,
    },
  },
}) => (
  <div className="activities">
    <Seo title="Zajęcia dla dzieci" />
    <Banner slides={bannerSlide} />
    <Container>
      <IntroSection text={introText} justify="center" />
      <Accordeon content={activitiesList} />
    </Container>
  </div>
);

export const query = graphql`
  {
    contentfulActivities {
      banner {
        bannerSlide {
          title
          image {
            image {
              gatsbyImageData(formats: WEBP)
            }
            altText
          }
        }
      }
      introText
      activitiesList {
        sectionTitle
        sectionTextItem
      }
    }
  }
`;

export default Zajecia;
