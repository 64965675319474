import React, { FC } from 'react';

import AccordeonItem from '../AccordeonItem';

import { AccordeonProps } from './models.d';

const Accordeon: FC<AccordeonProps> = ({ content }) => (
  <div className="accordeon">
    {content?.map((contentItem, i: number) => (
      <AccordeonItem key={contentItem} {...contentItem} idx={i} />
    ))}
  </div>
);

export default Accordeon;
