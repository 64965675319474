import React, { FC, useState } from 'react';
import classNames from 'classnames';

import { ICONS_SVG } from 'components/atoms/IconsSVG';

import { getIconSvg } from 'utils/getIconSvg';

import { AccordeonItemProps } from './models.d';

const AccordeonItem: FC<AccordeonItemProps> = ({ sectionTitle, sectionTextItem, idx }) => {
  const [isOpened, setIsOpened] = useState<boolean>(false);

  const itemListClassnames = classNames('accordeon-item__list', {
    'accordeon-item__list--opened': isOpened,
  });
  const itemTitleClassnames = classNames('accordeon-item__title', {
    'accordeon-item__title--opened': isOpened,
  });

  return (
    <div className="accordeon-item" style={{ animationDelay: `${idx * 0.2}s` }}>
      <button className={itemTitleClassnames} type="button" onClick={() => setIsOpened(!isOpened)}>
        {sectionTitle}
        {getIconSvg(
          isOpened ? ICONS_SVG.VARIANT.MINUS : ICONS_SVG.VARIANT.PLUS,
          'accordeon-item__expand-icon',
        )}
      </button>

      <div className={itemListClassnames}>
        {sectionTextItem?.map((item) => (
          <p key={item} className="accordeon-item__item">
            {item}
          </p>
        ))}
      </div>
    </div>
  );
};

export default AccordeonItem;
